import React from 'react';
import ServiceDetails from '../components/ServiceDetails/ServiceDetails';

const ServiceDetailsPages = () => {
    return (
        <>
            <ServiceDetails />
        </>
    )
}

export default ServiceDetailsPages;