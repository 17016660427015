import React from 'react';
import About from '../components/About/About';

const AboutPage = () => {
    return (
        <>
            <About />
        </>
    )
}

export default AboutPage;