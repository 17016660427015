import React from 'react'
import Ftcf from '../components/Ftcf/Ftcf'

const FtcfPage = () => {
  return (
    <div>
        <Ftcf/>
    </div>
  )
}

export default FtcfPage