import React from 'react';
import Faq from '../components/Faq/Faq.jsx';

const FaqPage = () => {
    return (
        <>
            <Faq />
        </>
    )
}

export default FaqPage;