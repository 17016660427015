export const APP_CONFIG = {
    emailJs: {
        publicKey: 'Hd232LSXgZ9-if65G',
        service: 'service_6z1uvjj',
        contact_template: 'template_hcrqwg8',
        ftcf_template: 'template_qxj0ldk'
    },
    phoneLink: 'tel:+16478556177',
    phone: '+1(647)855-6177',
    email: 'info@orientalbusinesssolutions.ca',
    emailLink: 'mailto:info@orientalbusinesssolutions.ca',
    address: '4 Robert Speck Pkwy #1500, Mississauga, ON L4Z 1S1',
    address2line: '4 Robert Speck Pkwy, Mississauga, ON L4Z 1S1',
    addressLink: 'https://www.google.com/maps?q=4+Robert+Speck+Pkwy+%231500,+Mississauga,+ON+L4Z+1S1',
    gmapLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.4471835333743!2d-79.63857822240347!3d43.59722987110489!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b473b287e3351%3A0xc4338c578acef23e!2sMississauga%20Tax%20Consulting%20-%20International%20Tax%20-%20US%20Tax%20-%20Corporate%20Tax%20-%20Personal%20Tax!5e0!3m2!1sen!2sca!4v1720753694958!5m2!1sen!2sca',
    whatsAppLink: `https://wa.me/+16478556177?text=${encodeURIComponent("Hello, I\'m interested in your services. Could you please let me know your available times to connect? Thank you!")}`,
    facebookLink: "https://www.facebook.com/oriental2022?mibextid=LQQJ4d",
    instaLink: "https://www.instagram.com/orientalbusinesssolutions?igsh=MTVyczU5MHpiajgydQ==",
    linkedInLink: "https://www.linkedin.com/in/orientalbusiness",

}