import React from 'react';
import Contact from '../components/Contact/Contact';

const ContactPage = () => {
    return (
        <>
            <Contact />
        </>
    )
}

export default ContactPage;