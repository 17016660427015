import React from 'react';
import Blog from '../components/Blog/Blog';

const BlogPage = () => {
    return (
        <>
            <Blog />
        </>
    )
}

export default BlogPage;